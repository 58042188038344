import { SERVER_PATH, APIV2_PATH, AS_API_PATH } from './constants';
import { axiosInstance } from './axios-interceptor';

const getHeader = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

const userAPI = {
  getUserInfo() {
    return axiosInstance.get(
      `${AS_API_PATH}openid/userinfo`,
      getHeader(),
    );
  },
  getUser(userId) {
    return axiosInstance.get(
      `${APIV2_PATH}users/${userId}/`,
      getHeader(),
    );
  },
  addUser(body) {
    return axiosInstance.post(
      `${APIV2_PATH}users/`,
      body,
      getHeader(),
    );
  },
  patchUser(userId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}users/${userId}/`,
      body,
      getHeader(),
    );
  },
  deactivateUser(userId) {
    return axiosInstance.post(
      `${APIV2_PATH}users/${userId}/disable/`,
      {},
      getHeader(),
    );
  },
  getAuditLogs(userId, page, size) {
    return axiosInstance.get(
      `${APIV2_PATH}users/${userId}/audit-logs/?page=${page}&size=${size}`,
      getHeader(),
    );
  },
  getUsers(clientId) {
    return axiosInstance.get(
      `${APIV2_PATH}users/?client_id=${clientId}`,
      getHeader(),
    );
  },
  listUsers(page) {
    return axiosInstance.get(
      `${APIV2_PATH}users/?with_subclients=true&page=${page}&size=100`,
      getHeader(),
    );
  },
};

const ASuserAPI = {
  getASUserSettings() {
    return axiosInstance.get(
      `${AS_API_PATH}settings/`,
      getHeader(),
    );
  },
  getASUser(userId) {
    return axiosInstance.get(
      `${AS_API_PATH}user/${userId}`,
      getHeader(),
    );
  },
  postASUserUnlock(userId) {
    return axiosInstance.post(
      `${AS_API_PATH}user/${userId}/unlock`,
      {},
      getHeader(),
    );
  },
  getASUserInvitationLink(userId) {
    return axiosInstance.get(
      `${AS_API_PATH}user/${userId}/get-invitation-link`,
      getHeader(),
    );
  },
};

const clientAPI = {
  listClients(page, size, searchQuery) {
    return axiosInstance.get(
      `${APIV2_PATH}clients/?page=${page}&size=${size}${searchQuery || ''}`,
      getHeader(),
    );
  },
  addClient(name, parentId, allowcsv, body) {
    return axiosInstance.post(
      `${APIV2_PATH}clients/?name=${name}&parent_id=${parentId}&allow_csv=${allowcsv}`,
      body,
      getHeader(),
    );
  },
  getClient(clientId) {
    return axiosInstance.get(
      `${APIV2_PATH}clients/${clientId}/`,
      getHeader(),
    );
  },
  editClient(clientId, name, parentId, allowcsv, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}clients/${clientId}/?name=${name}&parent_id=${parentId}&allow_csv=${allowcsv}`,
      body,
      getHeader(),
    );
  },
};

const gameplayAPI = {
  invalidateGameplay(gameplayId) {
    return axiosInstance.post(
      `${APIV2_PATH}gameplays/${gameplayId}/invalidate`,
      {},
      getHeader(),
    );
  },
  getRawDataGameplay(gameplayId) {
    return axiosInstance.get(
      `${APIV2_PATH}gameplays/${gameplayId}/raw-data`,
      getHeader(),
    );
  },
};

const gameAPI = {
  getGame(gameId, allowVersion) {
    return axiosInstance.get(
      `${APIV2_PATH}games/${gameId}/?allow_experimental_version=${allowVersion}`,
      getHeader(),
    );
  },
  listGames(page, state = 'active') {
    return axiosInstance.get(
      `${APIV2_PATH}games/?state=${state}&page=${page}&size=100`,
      getHeader(),
    );
  },
  editGame(gameId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}games/${gameId}/`,
      body,
      getHeader(),
    );
  },
  editLevelPack(gameId, levelpackId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}games/${gameId}/levelpacks/${levelpackId}`,
      body,
      getHeader(),
    );
  },
  addLevelPack(gameId, body) {
    return axiosInstance.post(
      `${APIV2_PATH}games/${gameId}/levelpacks/`,
      body,
      getHeader(),
    );
  },
  listLevelPacks(page, gameId, state = 'stable') {
    return axiosInstance.get(
      `${APIV2_PATH}games/${gameId}/levelpacks/?state=${state}&page=${page}&size=100`,
      getHeader(),
    );
  },
  getLevelPack(levelpackId, gameId) {
    return axiosInstance.get(
      `${APIV2_PATH}games/${gameId}/levelpacks/${levelpackId}`,
      getHeader(),
    );
  },
};

const privacyPolicyAPI = {
  listPolicies(page, size = 100) {
    return axiosInstance.get(
      `${APIV2_PATH}privacy-policies/?page=${page}&size=${size}`,
      getHeader(),
    );
  },
  addPolicy(body) {
    return axiosInstance.post(
      `${APIV2_PATH}privacy-policies/`,
      body,
      getHeader(),
    );
  },
  getPolicy(policyId) {
    return axiosInstance.get(
      `${APIV2_PATH}privacy-policies/${policyId}/`,
      getHeader(),
    );
  },
  defaultPolicy() {
    return axiosInstance.get(
      `${APIV2_PATH}privacy-policies/default/`,
      getHeader(),
    );
  },
  editPolicy(smallChange, policyId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}privacy-policies/${policyId}/?small_change=${smallChange}`,
      body,
      getHeader(),
    );
  },
};

const assessmentAPI = {
  listAssessments(page) {
    return axiosInstance.get(
      `${APIV2_PATH}assessments/?page=${page}&size=5000`,
      getHeader(),
    );
  },
  listAssessmentsPlayers(assessmentId, page, size, order_by, filter_by) {
    let url = `${APIV2_PATH}assessments/A-${assessmentId}/candidates/?page=${page}&size=${size}`;

    if (order_by) {
      url += `&order_by=${order_by}`;
    }
    if (filter_by) {
      url += `&filter_by=${filter_by}`;
    }

    return axiosInstance.get(url, getHeader());
  },
};

const aggregatorAPI = {
  listAggregators(page, size, searchQuery) {
    return axiosInstance.get(
      `${APIV2_PATH}aggregators/?page=${page}&size=${size}${searchQuery || ''}`,
      getHeader(),
    );
  },
  addAggregator(body) {
    return axiosInstance.post(
      `${APIV2_PATH}aggregators/`,
      body,
      getHeader(),
    );
  },
  getAggregator(aggregatorId) {
    return axiosInstance.get(
      `${APIV2_PATH}aggregators/${aggregatorId}/`,
      getHeader(),
    );
  },
  editAggregator(aggregatorId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}aggregators/${aggregatorId}/`,
      body,
      getHeader(),
    );
  },
  cloneAggregator(aggregatorId, body) {
    return axiosInstance.post(
      `${APIV2_PATH}aggregators/${aggregatorId}/clone/`,
      body,
      getHeader(),
    );
  },
  regenerateAllReports(aggregatorId) {
    return axiosInstance.get(
      `${APIV2_PATH}aggregators/${aggregatorId}/regenerate-all`,
      getHeader(),
    );
  },
  getRawReport(playerId, aggregatorId) {
    return axiosInstance.get(
      `${APIV2_PATH}aggregators/${aggregatorId}/players/P-${playerId}/report/`,
      getHeader(),
    );
  },
  forceRegenerateReport(aggregatorId, playerId) {
    return axiosInstance.get(
      `${APIV2_PATH}aggregators/${aggregatorId}/players/P-${playerId}/generate-report?force=true`,
      getHeader(),
    );
  },
  listVideoCards(page) {
    return axiosInstance.get(
      `${APIV2_PATH}video-interview-steps/?page=${page}&size=100`,
      getHeader(),
    );
  },
  addVideoCard(body) {
    return axiosInstance.post(
      `${APIV2_PATH}video-interview-steps/`,
      body,
      getHeader(),
    );
  },
  getVideoCard(cardId) {
    return axiosInstance.get(
      `${APIV2_PATH}video-interview-steps/${cardId}/`,
      getHeader(),
    );
  },
  editVideoCard(cardId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}video-interview-steps/${cardId}/`,
      body,
      getHeader(),
    );
  },
  listRegistationCards() {
    return axiosInstance.get(
      `${APIV2_PATH}registration-steps`,
      getHeader(),
    );
  },
};

const projectAPI = {
  addProject(body) {
    return axiosInstance.post(
      `${APIV2_PATH}projects/`,
      body,
      getHeader(),
    );
  },
  getProject(projectId) {
    return axiosInstance.get(
      `${APIV2_PATH}projects/${projectId}/`,
      getHeader(),
    );
  },
  editProject(projectId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}projects/${projectId}/`,
      body,
      getHeader(),
    );
  },
  listProjects(page, notMembers) {
    let url = `${APIV2_PATH}projects/?page=${page}&size=100`;

    if (notMembers) {
      url = `${APIV2_PATH}projects/?page=${page}&size=100&not_members_only=True`;
    }
    return axiosInstance.get(
      url,
      getHeader(),
    );
  },
};

const reportAPI = {
  listReportWarnings(page, size = 100) {
    return axiosInstance.get(
      `${APIV2_PATH}report-warnings/?page=${page}&size=${size}&lang=en`,
      getHeader(),
    );
  },
  addReportWarning(body) {
    return axiosInstance.post(
      `${APIV2_PATH}report-warnings/`,
      body,
      getHeader(),
    );
  },
  getReportWarning(warningId) {
    return axiosInstance.get(
      `${APIV2_PATH}report-warnings/${warningId}/`,
      getHeader(),
    );
  },
  editReportWarning(warningId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}report-warnings/${warningId}/`,
      body,
      getHeader(),
    );
  },
  listKpis(page) {
    return axiosInstance.get(
      `${APIV2_PATH}kpis/?page=${page}&size=100`,
      getHeader(),
    );
  },
  addKpi(body) {
    return axiosInstance.post(
      `${APIV2_PATH}kpis/`,
      body,
      getHeader(),
    );
  },
  getKpi(kpiId) {
    return axiosInstance.get(
      `${APIV2_PATH}kpis/${kpiId}/`,
      getHeader(),
    );
  },
  editKpi(kpiId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}kpis/${kpiId}/`,
      body,
      getHeader(),
    );
  },
};

const notificationAPI = {
  getUserNotifications(userId) {
    return axiosInstance.get(
      `${APIV2_PATH}notifications?filter_by={"user_id":"${userId}"}`,
      getHeader(),
    );
  },
  createUserNotification(user_id, assessment_id, notification_type) {
    return axiosInstance.post(
      `${APIV2_PATH}notifications`,
      {
        user_id,
        assessment_id,
        notification_type,
      },
      getHeader(),
    );
  },
  deleteUserNotification(notificationId) {
    return axiosInstance.delete(
      `${APIV2_PATH}notifications/${notificationId}`,
      getHeader(),
    );
  },
};

const layoutAPI = {
  listSlugs(page) {
    return axiosInstance.get(
      `${APIV2_PATH}assessment-slugs/?page=${page}&size=5000`,
      getHeader(),
    );
  },
  listReportLayouts(page) {
    return axiosInstance.get(
      `${APIV2_PATH}report-layouts/?page=${page}&size=5000`,
      getHeader(),
    );
  },
  addLayout(body) {
    return axiosInstance.post(
      `${APIV2_PATH}report-layouts/`,
      body,
      getHeader(),
    );
  },
  getReporLayout(layoutId) {
    return axiosInstance.get(
      `${APIV2_PATH}report-layouts/${layoutId}/`,
      getHeader(),
    );
  },
  editReporLayout(layoutId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}report-layouts/${layoutId}/`,
      body,
      getHeader(),
    );
  },
  listAdditionalLayouts(page, size) {
    return axiosInstance.get(
      `${APIV2_PATH}additional-layouts/?page=${page}&size=${size}`,
      getHeader(),
    );
  },
  listReportModules(page, moduleType, size) {
    return axiosInstance.get(
      `${APIV2_PATH}report-modules/${moduleType}/?page=${page}&size=${size}`,
      getHeader(),
    );
  },
  addModule(moduleType, body) {
    return axiosInstance.post(
      `${APIV2_PATH}report-modules/${moduleType}/`,
      body,
      getHeader(),
    );
  },
  getModule(moduleType, moduleId) {
    return axiosInstance.get(
      `${APIV2_PATH}report-modules/${moduleType}/${moduleId}`,
      getHeader(),
    );
  },
  editModule(moduleType, moduleId, body) {
    return axiosInstance.post(
      `${APIV2_PATH}report-modules/${moduleType}/${moduleId}/`,
      body,
      getHeader(),
    );
  },
};

const playerAPI = {
  getPlayerData(playerId) {
    return axiosInstance.get(
      `${APIV2_PATH}players/${playerId}`,
      getHeader(),
    );
  },
  getPlayerState(playerId, aggregatorId) {
    return axiosInstance.get(
      `${APIV2_PATH}players/P-${playerId}/state/${aggregatorId}.json`,
      getHeader(),
    );
  },
};

const playAPI = {
  listPlayCards(page, size = 100) {
    return axiosInstance.get(
      `${APIV2_PATH}play-cards/?page=${page}&size=${size}`,
      getHeader(),
    );
  },
  addPlayCard(body) {
    return axiosInstance.post(
      `${APIV2_PATH}play-cards/`,
      body,
      getHeader(),
    );
  },
  getPlayCard(cardId) {
    return axiosInstance.get(
      `${APIV2_PATH}play-cards/${cardId}/`,
      getHeader(),
    );
  },
  editPlayCard(cardId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}play-cards/${cardId}/`,
      body,
      getHeader(),
    );
  },
};

const calculatorAPI = {
  listCalculators(page) {
    return axiosInstance.get(
      `${APIV2_PATH}profile-calculators/?page=${page}&size=5000`,
      getHeader(),
    );
  },
  editCalculator(profileId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}profile-calculators/${profileId}`,
      body,
      getHeader(),
    );
  },
  addCalculator(body) {
    return axiosInstance.post(
      `${APIV2_PATH}profile-calculators/`,
      body,
      getHeader(),
    );
  },
  listCalculatorConfig() {
    return axiosInstance.get(
      `${APIV2_PATH}profile-calculators/configs`,
      getHeader(),
    );
  },
  getCalculator(profileId) {
    return axiosInstance.get(
      `${APIV2_PATH}profile-calculators/${profileId}/`,
      getHeader(),
    );
  },
};

const customFieldAPI = {
  listCustomFields() {
    return axiosInstance.get(
      `${APIV2_PATH}custom-field-types/`,
      getHeader(),
    );
  },
  getCustomField(id) {
    return axiosInstance.get(
      `${APIV2_PATH}custom-field-types/${id}/`,
      getHeader(),
    );
  },
  addCustomField(body) {
    return axiosInstance.post(
      `${APIV2_PATH}custom-field-types/`,
      body,
      getHeader(),
    );
  },
  editCustomField(id, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}custom-field-types/${id}/`,
      body,
      getHeader(),
    );
  },
};

const servicesAPI = {
  listPMEvulator() {
    return axiosInstance.get(
      `${APIV2_PATH}services/digital-ocean/pm-evaulators`,
      getHeader(),
    );
  },
  listEmailTemplates(page) {
    return axiosInstance.get(
      `${APIV2_PATH}services/sendgrid/templates/?page=${page}&size=5000`,
      getHeader(),
    );
  },
  listPMCalculatorJson() {
    return axiosInstance.get(
      `${APIV2_PATH}services/digital-ocean/configs?filetype=json`,
      getHeader(),
    );
  },
  listPMCalculatorPickle() {
    return axiosInstance.get(
      `${APIV2_PATH}services/digital-ocean/configs?filetype=pickle`,
      getHeader(),
    );
  },
  getPMCalcConfigVersions(filename) {
    return axiosInstance.get(
      `${APIV2_PATH}services/digital-ocean/config-versions/${filename}`,
      getHeader(),
    );
  },
  getPMCalcConfigDownload(filename,version) {
    return axiosInstance.get(
      `${APIV2_PATH}services/digital-ocean/configs/${filename}/download?version=${version}`,
      getHeader(),
    );
  },
  getPMCalcConfig(filename, version) {
    return axiosInstance.get(
      `${APIV2_PATH}services/digital-ocean/configs/${filename}?version=${version}`,
      getHeader(),
    );
  },
  getPMCalcConfigDelete(filename, version) {
    return axiosInstance.delete(
      `${APIV2_PATH}services/digital-ocean/configs/${filename}?version=${version}`,
      getHeader(),
    );
  },
  postPMCalcConfigUpload(path_to_file, body) {
    return axiosInstance.post(
      `${APIV2_PATH}services/digital-ocean/configs/${path_to_file}`,
      body,
      getHeader(),
    );
  },
};

const searchAPI = {
  searchInvite(invite) {
    return axiosInstance.get(
      `${APIV2_PATH}search/invites/?invite_code=${invite}`,
      getHeader(),
    );
  },
  searchGameplay(uuid, created_at_from, created_at_to, page, size) {
    let queryString = '';
    if (uuid !== null && uuid !== undefined) {
      queryString += `uuid=${uuid}`;
    }

    if (created_at_from !== null && created_at_from !== undefined) {
      if (queryString !== '') {
        queryString += '&';
      }
      queryString += `created_at_from=${created_at_from}`;
    }

    if (created_at_to !== null && created_at_to !== undefined) {
      if (queryString !== '') {
        queryString += '&';
      }
      queryString += `created_at_to=${created_at_to}`;
    }

    if (page !== null && page !== undefined) {
      if (queryString !== '') {
        queryString += '&';
      }
      queryString += `page=${page}`;
    }

    if (size !== null && size !== undefined) {
      if (queryString !== '') {
        queryString += '&';
      }
      queryString += `size=${size}`;
    }

    return axiosInstance.get(`${APIV2_PATH}search/gameplays/?${queryString}`, getHeader());
  },
  searchPlayer(name, external_id, uuid, email, page, size) {
    return axiosInstance.get(
      `${APIV2_PATH}search/players/?uuid=${uuid}&name=${name}&external_id=${external_id}&email=${email}&page=${page}&size=${size}`,
      getHeader(),
    );
  },
  getPlayer(playerId, page, size) {
    return axiosInstance.get(
      `${APIV2_PATH}search/players/${playerId}/?page=${page}&size=${size}`,
      getHeader(),
    );
  },
};

const benchmarkAPI = {
  listBenchmarks() {
    return axiosInstance.get(
      `${APIV2_PATH}benchmarks/?page=1&size=5000`,
      getHeader(),
    );
  },
  getBenchmark(benchmarkId) {
    return axiosInstance.get(
      `${APIV2_PATH}benchmarks/${benchmarkId}/`,
      getHeader(),
    );
  },
  editBenchmark(benchmarkId, body) {
    return axiosInstance.patch(
      `${APIV2_PATH}benchmarks/${benchmarkId}/`,
      body,
      getHeader(),
    );
  },
  addBenchmark(body) {
    return axiosInstance.post(
      `${APIV2_PATH}benchmarks/`,
      body,
      getHeader(),
    );
  },
};

const defaultAPI = {
  getLanguages() {
    return axiosInstance.get(
      `${APIV2_PATH}languages/`,
      getHeader(),
    );
  },
  getStatus() {
    const modifiedServerPath = SERVER_PATH.replace('/api/v1/', '');
    return axiosInstance.get(
      `${modifiedServerPath}/api/internal/status.json`,
      getHeader(),
    );
  },
};

const api = {
  ...userAPI,
  ...ASuserAPI,
  ...clientAPI,
  ...gameplayAPI,
  ...gameAPI,
  ...privacyPolicyAPI,
  ...assessmentAPI,
  ...aggregatorAPI,
  ...projectAPI,
  ...reportAPI,
  ...notificationAPI,
  ...layoutAPI,
  ...playerAPI,
  ...playAPI,
  ...calculatorAPI,
  ...customFieldAPI,
  ...servicesAPI,
  ...searchAPI,
  ...benchmarkAPI,
  ...defaultAPI
};

export default api;

